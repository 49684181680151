
import React, { useEffect, useState } from "react";
import { Popover, Table } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ACADEMIC, SIS_STORAGE, STUDENT_ACADEMIC, STUDENT_PROFILE } from "../../apis/apis";
import { render } from "@testing-library/react";
import { ExclamationCircleOutlined, FilterFilled } from "@ant-design/icons";
import SisLoader from "../../widgets/loader";

function MyAcademic() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [enrollments_students, set_enrollments_students] = useState("");
  const [table_data, set_table_data] = useState("");
  const [course_semester_registrations, set_course_semester_registrations] =useState("");
  const [total_credits, set_total_credits] = useState("");
  const [total_earn_credits, set_total_earn_credits] = useState("");

  const [loader, set_loader] = useState(true);
  const [my_academic_details, set_my_academic_details] = useState("");
  const [attendances_summary, set_attendances_summary] = useState("");
  const [programme_progress, set_programme_progress] = useState("");
  const [current_course, set_current_course] = useState("");


  const get_timing = (data, selected_days) => {
    return (
      <>
        {selected_days.map((item) => (
          <>
            {item?.start_time && (
              <>
                {data?.timing ? (
                  <>
                    <span
                      style={{
                        width: "110px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "12px",
                      }}
                    >
                      {item?.value?.slice(0, 2).toUpperCase()}{" "}
                      {item?.start_time} - {item?.end_time}
                    </span>
                    <br />
                  </>
                ) : (
                  <>
                    <span style={{ color: "red" }}>Not Available</span>
                  </>
                )}
              </>
            )}
          </>
        ))}
      </>
    );
  };
  
  const columns = [
    {
      title: "Courses" , 
      dataIndex: "course_title",
      key: "course_title",
      sorter: (a, b) => a.course_title.localeCompare(b.course_title),
    },
    {
      title: "Courses Code",
      dataIndex: "course_code",
      key: "course_code",
    },
    {
      title: "Total Credits",
      dataIndex: "course_units",
      key: "course_units",
    },
    {
      title: "Given Credits",
      dataIndex: "given_credits",
      key: "given_credits",
    },
    {
      title: "Room",
      render: (text, record) => {
        return (
          <>
            {record?.room_name} {record?.capacity}
          </>
        );
      },
    },
    {
      title: "Timing",
      render: (text, record) => {
        var timingData = record?.timing ? JSON.parse(record.timing) : null;
        return <>{timingData && get_timing(record, timingData)}</>;
      },
    },
    {
      title: "Faculty",
      dataIndex: "faculty_name",
      key: "faculty_name",
    },
    {
      title: "Course Status",
      dataIndex: "course_status",
      key: "course_status",
    },
  ];

  const VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data")); 

    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", USER_DATA.id);
    FORM_DATA.append("token", USER_DATA.token);
    const EDIT_API_RESPONSE = await STUDENT_ACADEMIC(FORM_DATA);
    if (EDIT_API_RESPONSE?.data?.status); {
      set_my_academic_details(EDIT_API_RESPONSE?.data?.my_academics?.my_academic_details);
      set_attendances_summary(EDIT_API_RESPONSE?.data?.my_academics?.attendances_summary);
      set_programme_progress(EDIT_API_RESPONSE?.data?.my_academics?.programme_progress);
      set_current_course(EDIT_API_RESPONSE?.data?.my_academics?.current_course);
      set_loader(false)
    }
  };
  useEffect(() => {
    VIEW_API();
  }, [loader]);
  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>
        <div>
        <h3 className="first-letter-capitalize" style={{color:"#999",padding:"20px 0"}}>My Academic Details</h3>
        </div>
          <div>
            <div className="col-3 " style={{display:"flex", flexDirection:"column", paddingLeft:"0"}}>
              <p className="first-letter-capitalize">Student Type</p>
              <h3>{my_academic_details?.student_type ? my_academic_details?.student_type : 'N/A'}</h3>
            </div>
            <div className="col-3" style={{display:"flex", flexDirection:"column"}}>
              <p className="first-letter-capitalize">Program/ Degree</p>
              <h3>{my_academic_details?.programmes_title ? my_academic_details?.programmes_title : 'N/A'}</h3>
            </div>
            <div className="col-3" style={{display:"flex", flexDirection:"column"}}>
              <p className="first-letter-capitalize">Program Status</p>
              <h3>{my_academic_details?.programme_status ? my_academic_details?.programme_status : 'N/A'}</h3>
            </div>
            <div className="col-3" style={{display:"flex", flexDirection:"column"}}>
              <p className="first-letter-capitalize">Matriculation Semester</p>
              <h3>{my_academic_details?.matriculation_semester_title ? my_academic_details?.matriculation_semester_title : 'N/A'}</h3>
            </div>
          </div>
          <hr style={{color:"#999", margin:"70px 0 20px 0"}}/>
          <div>
        <h3 className="first-letter-capitalize" style={{color:"#999",padding:"20px 0"}}>Attendance Summary</h3>
        </div>
          <div className="first-letter-capitalize" style={{color:"#999"}}>For the</div>
          <h4 style={{color:"#777"}}>{attendances_summary?.calendar_title}</h4>
          <br></br>
          <div className="attendance-summary">
        <div className="attendance-item">
            <div className="attendance-label first-letter-capitalize">Absent unexcused</div>
            <div className="attendance-value">{attendances_summary?.absent_unexcused}</div>
        </div>
        <div className="attendance-item">
            <div className="attendance-label first-letter-capitalize">Absent Excused</div>
            <div className="attendance-value">{attendances_summary?.absent_excused}</div>
        </div>
        <div className="attendance-item">
            <div className="attendance-label first-letter-capitalize">Tardy Excused</div>
            <div className="attendance-value">{attendances_summary?.tardy_excused}</div>
        </div>
    </div>
          <br></br>
          <h3 className="first-letter-capitalize" style={{ color: "#8E8E8E", }}>Program Progress</h3>
          <br></br>
          <div className="programme-attendance-summary">
        <div className="programme-attendance-item">
            <div className="programme-attendance-label first-letter-capitalize">Credit Earned</div>
            <div className="programme-attendance-value ">{programme_progress?.credit_earned}</div>
        </div>
        <div className="programme-attendance-item">
            <div className="programme-attendance-label first-letter-capitalize">In Progress</div>
            <div className="attendance-value">{programme_progress?.in_progress}</div>
        </div>
        <div className="programme-attendance-item">
            <div className="programme-attendance-label first-letter-capitalize">Credit Remaining</div>
            <div className="programme-attendance-value">{programme_progress?.credit_remain}</div>
        </div>
        <div className="programme-attendance-item">
            <div className="programme-attendance-label first-letter-capitalize">Total Credit</div>
            <div className="programme-attendance-value">{programme_progress?.table_credit}</div>
        </div>
    </div>
          <br></br>
          <h3 className="first-letter-capitalize" style={{ color: "#8E8E8E", }}>Current Courses</h3>
          <br></br>
          {current_course?.length > 0 ?
          <div style={{ border: "1px solid #E1E1E1",padding:"10px", borderRadius:"5px"}} >
          <div className="container" style={{ color: "#3B82F6",padding:"10px 0" }}  >
            <div className="col-6 first-letter-capitalize">Attempted Courses Units</div>
            <div className="col-6" >{programme_progress?.in_progress}</div>
          </div>
          <div > 
            {loader ? <SisLoader /> : 
            <Table  columns={columns}  dataSource={current_course} />}
          </div>
        </div>
          :<> <p style={{ margin: "50px 0", textAlign: "center", color: "red", fontSize: "14px" }}>No Current Courses Provided Yet.</p></>
          }
        </>
      )}
    </>
  );
}

export default MyAcademic;