import React, { useEffect, useState } from 'react';
import './layout.css';
import Sider from 'antd/es/layout/Sider';
import user_icon from './../images/user.png';
import { Button, Layout, Menu, theme } from 'antd';
import { CalendarOutlined, DashboardOutlined, FormOutlined, MenuFoldOutlined, MenuUnfoldOutlined, SafetyCertificateOutlined, SnippetsOutlined, UploadOutlined, UserOutlined, UserSwitchOutlined, UsergroupAddOutlined, VideoCameraOutlined, LoginOutlined } from '@ant-design/icons';
import { Content, Header } from 'antd/es/layout/layout';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import logoTheme from './../images/fav.png';
import LayoutStudentName from './layoutStudentName';
const SisLayout = () => {
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const [selected_menu, set_selected_menu] = useState();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const [storageData, setStorageData] = useState([]);
    useEffect(() => {
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        setStorageData(USER_DATA);
        var location_pathname = window.location.pathname;
        if (location_pathname === '/') {
            set_selected_menu(['1']);
        }
        if (location_pathname === '/students-profile-detail') {
            set_selected_menu(['2']);
        }
        if (location_pathname === '/students-academic-detail') {
            set_selected_menu(['3']);
        }
        if (location_pathname === '/students-grade-detail') {
            set_selected_menu(['4']);
        }
    })
    return (
        <>

            <Layout>
                <Sider 
                style={{ backgroundColor: " #900000" }}
                trigger={null} collapsible collapsed={collapsed}>
                    <div className='logo-user-box'>
                        <div className='logo-area'>
                            <img className='theme-logo' src={logoTheme} />
                        </div>
                        {!collapsed && <>
                            <h2>Lincoln University</h2>
                            <p>Student Information System</p>
                            <LayoutStudentName/>
                        </>}

                    </div>
                    <Menu
                      style={{ backgroundColor: " #900000" }}
                        theme="dark"
                        mode="inline"
                        selectedKeys={selected_menu}
                        items={[
                            {
                                key: '1',
                                icon: <DashboardOutlined />,
                                label: 'Dashboard',
                                onClick: function (e) {

                                    navigate('/')
                                }
                            },
                           
                            {
                                key: '2',
                                icon: <UserOutlined />,
                                 label: 'My Profile',
                                 onClick: function (e) {
                                   navigate('/students-profile-detail')
                                }
                               },
                               {
                                key: '3',
                                icon: <UserOutlined />,
                                 label: 'My Academics',
                                 onClick: function (e) {
                                   navigate('/students-academic-detail')
                                }
                               },

                               {
                                key: '4',
                                icon: <UserOutlined />,
                                 label: 'My Grades',
                                 onClick: function (e) {
                                   navigate('/students-grade-detail')
                                }
                               },

                            {
                                key: '12',
                                icon: <LoginOutlined />,
                                label: 'Logout',
                                onClick: function (e) {
                                    localStorage.clear();
                                    window.location = "https://lusis.lincolnuca.edu";
                                }
                            }
                        ]}
                    />
                </Sider>
                <Layout>
                    <Header style={{ padding: 0, background: colorBgContainer }}>
                        <Button
                            type="text"
                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                                fontSize: '16px',
                                width: 64,
                                height: 64,
                            }}
                        />
                    </Header>
                    <Content
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            minHeight: window.innerHeight,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};

export default SisLayout;