import axios from 'axios';
//export const BACKEND_URL = 'http://localhost/lu-sis';
export const BACKEND_URL = 'https://luserv.lincolnuca.edu';
//export const BACKEND_URL = 'https://dev.icore.uk';
//const URL = 'http://localhost/lu-sis/api/';
const URL = 'https://luserv.lincolnuca.edu/student-api/';
//const URL = 'https://dev.icore.uk/student-api/';

const Axios = axios.create({
    baseURL: URL,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
       ' Access-Control-Allow-Origin': '*'
    },
});



export default Axios;

