import axios from 'axios';

export const BACKEND_URL = 'https://luserv.lincolnuca.edu';


const adminURL = 'https://luserv.lincolnuca.edu/api/';


const adminAxios = axios.create({
    baseURL: adminURL,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
       ' Access-Control-Allow-Origin': '*'
    },
});

export default adminAxios;
